<template>
  <div
    class="w-full flex flex-col"
  >
    <BaseText
      type="label"
      size="md"
      class="text-text-loud mb-4"
    >
      1. Choose a Plan
    </BaseText>
    <ProductCard
      v-if="subscriptions[0]"
      :selected-subscription="selectedSubscription"
      :subscription="subscriptions[0]"
      :selected="selectedSubscription === subscriptions[0]"
      main-text="Inspiration Plan"
      sub-text="Unlimited Usage of Swipe File & Discovery"
      :price="paymentSchedule == 'Monthly' ? subscriptions[0]?.price.tiers[0].flat_amount_decimal : subscriptions[0]?.annualPrice.tiers[0].flat_amount_decimal"
      :payment-schedule="paymentSchedule"
      @click="() => $emit('planSelected', subscriptions[0])"
    />
    <ProductCard
      v-if="subscriptions[1]"
      :selected-subscription="selectedSubscription"
      :subscription="subscriptions[1]"
      :selected="selectedSubscription === subscriptions[1]"
      main-text="Full Workflow Plan"
      sub-text="“Inspiration Plan” + Unlimited Usage of Briefs"
      :discount-text="(timerExpired || paymentSchedule !== 'Monthly') ? '' : '$99/mo'"
      :price="getFullWorkflowPrice"
      :banner-text="(timerExpired || paymentSchedule !== 'Monthly') ? '' : '50% off your first month!' "
      :payment-schedule="paymentSchedule"
      class="mt-4"
      @click="() => $emit('planSelected', subscriptions[1])"
    />
  </div>
</template>
<script>
import ProductCard from './ProductCard.vue'
export default {
  name: 'PlanSelection',
  components: {
    ProductCard
  },
  props: {
    subscriptions: {
      type: Array,
      required: true
    },
    selectedSubscription: {
      type: Object,
      default: () => {}
    },
    paymentSchedule: {
      type: String,
      default: 'Monthly'
    },
    timerExpired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingSubscriptions: false
    }
  },
  computed: {
    getFullWorkflowPrice () {
      const fullWorkflowPrice = '099'
      if (!this.timerExpired && this.paymentSchedule === 'Monthly') {
        return fullWorkflowPrice
      } else {
        return this.paymentSchedule === 'Monthly' ? this.subscriptions[1]?.price.tiers[0].flat_amount_decimal : this.subscriptions[1]?.annualPrice.tiers[0].flat_amount_decimal
      }
    }
  }
}
</script>

<style scoped>
.custom-rounding {
  border-radius: 10px;
}

.green-border {
  border: 1px solid #40c4aa23;
}

.green-border--top {
  border-top: 1px solid #40c4aa23;
}

.green-text{
  color: #40c4aa;
}
</style>
