<template>
  <div
    class="flex flex-col items-center justify-center max-w-2xl w-full px-10 "
  >
    <BaseText
      type="label"
      size="md"
      class="text-text-loud w-full pt-5"
    >
      3. Billing Details
    </BaseText>
    <StripeElements
      v-if="stripeKey && elementOptions?.clientSecret"
      #default="{elements, instance}"
      ref="elms"
      :stripe-key="stripeKey"
      :elements-options="elementOptions"
      :options="{style}"
      class="w-full flex flex-col items-center justify-center max-w-2xl text-body-sm font-normal"
    >
      <BaseText
        type="body"
        size="sm"
        class="text-text-loud w-full pt-5"
      >
        Contact Information
      </BaseText>
      <StripeElement
        type="linkAuthentication"
        :elements="elements"
        :instance="instance"
        :elements-options="elementOptions"
        :options="{ ...addressOptions }"
        class="w-full"
      />
      <BaseText
        type="body"
        size="sm"
        class="text-text-loud w-full pt-5"
      >
        Billing
      </BaseText>
      <StripeElement
        ref="addressElms"
        type="address"
        :elements="elements"
        :instance="instance"
        :elements-options="elementOptions"
        :options="{ style, ...addressOptions }"
        class="w-full mt-1.5"
      />
      <div
        class="w-full"
      >
        <StripeElement
          type="payment"
          :elements="elements"
          :elements-options="elementOptions"
          :options="{ ...addressOptions, wallets: { googlePay: 'auto', applePay: 'auto' } }"
          class="w-full mt-1.5"
        />
      </div>
    </StripeElements>
    <input
      ref="taxId"
      type="text"
      placeholder="Tax ID (VAT Number)"
      class="mt-4 block w-full rounded-md outline-none border border-border-normal py-1.5 text-body-sm font-normal  focus:ring-2 focus:ring-border-disabled autofill focus:border-border-normal"
    >
    <BaseText
      type="body"
      size="sm"
      class="text-text-loud w-full pt-5 mb-2"
    >
      Promo Code
    </BaseText>
    <div
      class="w-full relative"
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute top-1 left-2"
      >
        <path
          d="M7.15395 6.39798L3.53326 11.648C3.06183 12.3316 3.06183 13.2353 3.53326 13.9189L7.15395 19.1689C7.52727 19.7102 8.14282 20.0334 8.80038 20.0334H19.2502C20.3548 20.0334 21.2502 19.138 21.2502 18.0334V7.53345C21.2502 6.42888 20.3548 5.53345 19.2502 5.53345H8.80038C8.14282 5.53345 7.52727 5.85667 7.15395 6.39798Z"
          stroke="#808899"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.6556 9.73108C12.6613 9.73108 11.9688 10.1821 11.9688 11.156C11.9688 12.2718 12.8278 12.5111 13.6556 12.7918C14.4835 13.0724 15.3425 13.3117 15.3425 14.4275C15.3425 15.4015 14.65 15.8525 13.6556 15.8525M13.6556 9.73108C14.3753 9.73108 14.8996 10.0712 15.1681 10.5531M13.6556 9.73108V8.54395M13.6556 15.8525C12.936 15.8525 12.4117 15.5124 12.1432 15.0304M13.6556 15.8525V17.0229"
          stroke="#808899"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="7.21521"
          cy="12.7834"
          r="0.875366"
          fill="#808899"
        />
      </svg>
      <form
        class="w-full flex items-center gap-2"
        @submit.prevent="checkPromoCode(true)"
      >
        <input
          ref="promoCode"
          type="text"
          placeholder="Enter Code"
          class="block w-full rounded-md outline-none border border-border-normal py-1.5 text-body-sm font-normal pl-10 focus:ring-2 focus:ring-border-disabled autofill focus:border-border-normal flex-1"
          :class="{'border-secondary-red-100': invalidPromoCode, 'border-primary-green-100': successPromoCode}"
          @input="invalidPromoCode = false"
        >
        <BaseButton
          outlined
          class="w-24 h-10"
          :loading="loadingPromoCode"
          type="submit"
        >
          Apply
        </BaseButton>
        <!-- END: ed8c6549bwf9 -->
      </form>
    </div>
    <BaseBadge
      v-if="promoCode && successPromoCode"
      class="mt-2 mr-auto"
      green
      small
      error
    >
      <BaseText
        type="label"
        size="xs"
        class="w-max"
      >
        {{ promoCode }}
      </BaseText>
      <TagRemoveIcon
        class="cursor-pointer -mr-1"
        stroke="#003D34"
        @click="removePromoCode"
      />
    </BaseBadge>
    <BaseBadge
      v-else-if="showDeal"
      class="mt-2 mr-auto"
      green
      small
      error
    >
      <BaseText
        type="label"
        size="xs"
        class="w-max"
      >
        50% off!
      </BaseText>
      <TagRemoveIcon
        class="cursor-pointer -mr-1"
        stroke="#003D34"
        @click="removePromoCode(true)"
      />
    </BaseBadge>

    <CheckoutTotal
      v-if="plan?.price"
      :plan="plan"
      :products="products"
      :payment-schedule="paymentSchedule"
      :timer-expired="timerExpired"
      :discount-message="discountMessage"
      :discount-price="discountPrice"
      :per-unit-price="perUnitPrice"
      :original-per-unit-price="originalPerUnitPrice"
      :discount-message-spyder="discountMessageSpyder"
      :discount-price-spyder="discountPriceSpyder"
      :loading-promo-code="loadingPromoCode"
      :original-price-spyder="originalPriceSpyder"
      :current-country="currentCountry"
      class="w-full mt-4"
    />

    <BaseButton
      primary
      class="mt-4 w-full"
      :loading="loading"
      :success="success"
      large
      @click="pay"
    >
      <BaseText
        type="label"
        size="sm"
      >
        Subscribe
      </BaseText>
    </BaseButton>
  </div>
</template>

<script>
import CheckoutTotal from '@/components/account/subcriptionv2/CheckoutTotal.vue'
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
import firebase from '../../../api/config/FirebaseConfig'
import API from '@/api'

export default {
  name: 'BillingDetails',
  components: {
    CheckoutTotal,
    StripeElement,
    StripeElements,
    TagRemoveIcon
  },
  props: {
    plan: {
      type: Object,
      default: () => {}
    },
    subscriptions: {
      type: Array,
      default: () => []
    },
    paymentSchedule: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    timerExpired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stripeKey: '',
      loading: false,
      success: false,
      addressOptions: {
        placeholder: 'Address',
        mode: 'billing',
        display: {
          name: 'split',
          autocomplete: {
            apiKey: 'AIzaSyC5vp8pT_pPkceYZ39312OwBC2P_2xAe5M&'
          }
        }
      },
      style: {
        base: {
          fontSize: '14px',
          fontWeight: 400,
          fontFamily: 'Inter, sans-serif',
          lineHeight: '20px',
          borderRadius: '4px'
        },
        invalid: {
          color: '#fa755a' // Text color for invalid input fields
        }
      },
      promoCode: null,
      invalidPromoCode: false,
      successPromoCode: false,
      elementOptions: {
        clientSecret: null,
        appearance: {
          theme: 'stripe',
          variables: {
            // border and email suggestion colour
            colorPrimary: '#1F69FF',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            spacingUnit: '2px',
            borderRadius: '4px'
            // See all possible variables below
          },
          rules: {
            // hide labels
            '.Label': {
              marginTop: '12px'
            },
            '.Input': {
              border: '1px solid #ECEFF3',
              borderRadius: '6px',
              padding: '6px',
              boxShadow: 'none'
            },
            '.p-FieldSuggestion': {
              colorText: '#c2c2c2'
            }
          }
        }
      },
      loadingPromoCode: false,
      placeholderIntervalId: null,
      // Price
      price: null,
      originalPerUnitPrice: null,
      discountMessage: null,
      discountPrice: null,
      perUnitPrice: null,
      // Spyder Price
      discountPriceSpyder: null,
      discountMessageSpyder: null,
      originalPriceSpyder: null,
      currentCountry: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getStripeCustomer', 'getTeam', 'getUserSubscription', 'getUser']),
    checkPropertyChange () {
      return `${this.plan?.name}|${this.paymentSchedule}|${this.products?.map((product) => product.amount).join('|')}`
    },
    showDeal () {
      return !this.timerExpired && this.paymentSchedule === 'Monthly' && this.plan?.name === 'Full Workflow'
    }
  },
  watch: {
    checkPropertyChange () {
      this.checkPromoCode()
    }
  },
  async created () {
    if (process.env.NODE_ENV === 'development') {
      console.log('=== development ===')
      this.stripeKey = 'pk_test_51JS7DeAQtq0TW7KZz4oPmzFVgxlULjWuUNKBoo0KOJjVi90wLS3VniuKgSLJHNHeFBNuY6f8jP9mD3bLygTj8BRM00aL3PiZeq'
    } else {
      console.log('=== production ===')
      this.stripeKey = 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN'
    }
    await this.getPaymentSetupIntent()
  },
  beforeDestroy () {
    clearInterval(this.placeholderIntervalId)
  },
  mounted () {
    this.placeholderIntervalId = setInterval(() => {
      console.log('setting placeholders')

      const firstNameInput = document.getElementById('Field-firstNameInput')
      const lastNameInput = document.getElementById('Field-lastNameInput')

      if (firstNameInput) {
        console.log('Inputs found placeholders')
        firstNameInput.placeholder = 'First Name'
        lastNameInput.placeholder = 'Last Name'
        clearInterval(this.placeholderIntervalId)
      }
    }, 1000)
    this.waitForAddressElement().then(() => {
      // console.log('address element found')
      const addressElement = this.$refs.addressElms.elements.getElement('address')
      // Add a listener for changes in the address element
      addressElement.on('change', async (event) => {
        // console.log('address change event:', event)
        const { complete, value } = await addressElement.getValue()

        if (complete && value.address && value.address.country) {
          // console.log('Address Element value:', value)
          this.currentCountry = value.address.country
        }
      })
    })
  },
  methods: {
    ...mapActions('AuthModule', ['setUserSubscription']),
    ...mapMutations('AuthModule', ['SET_TEAM_SUBSCRIPTION_STATE', 'SET_SHOW_UPSELL', 'SET_UPSELL_STEP']),
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions']),
    async getPaymentSetupIntent () {
      const { clientSecret } = await API.Stripe.createSubscriptionSetup(
        this.getStripeCustomer.stripeId
      )
      this.elementOptions.clientSecret = clientSecret
    },
    async waitForAddressElement () {
      return new Promise((resolve) => {
        const checkInterval = setInterval(async () => {
          const addressElement = this.$refs.addressElms?.elements?.getElement('address')
          if (addressElement) {
            const { complete, value } = await addressElement.getValue()

            if (complete) {
              console.log('Address Element value:', value)
              this.currentCountry = value.address.country
            }
            clearInterval(checkInterval)
            resolve()
          }
        }, 100)
      })
    },
    async checkPromoCode (getRefValue = false) {
      if (getRefValue) {
        this.promoCode = this.$refs.promoCode.value
      }

      if (!this.promoCode) return

      if (!this.timerExpired && this.paymentSchedule === 'Monthly' && this.plan?.name === 'Full Workflow') {
        this.removePromoCode(true, false)
      } else if (!this.timerExpired) {
        return this.removePromoCode()
      }

      this.loadingPromoCode = true
      let priceId

      if (this.paymentSchedule === 'Annual (Save 10%)') {
        priceId = this.plan.annualPrice.id
      } else {
        priceId = this.plan.price.id
      }

      try {
        const spyderQuantity = this.products?.find(
          (product) => product.name === 'Spyder Brands'
        )?.amount

        const paymentSchedule = this.paymentSchedule === 'Annual (Save 10%)' ? 'yearly' : 'monthly'

        const {
          originalPrice,
          discountPrice,
          discountMessage,
          perUnitPrice,
          originalPerUnitPrice,
          originalPriceSpyder,
          discountPriceSpyder,
          discountMessageSpyder
        } = await API.Stripe.checkPromoCode(priceId, this.promoCode, spyderQuantity, paymentSchedule)

        this.price = originalPrice
        this.discountMessage = discountMessage
        this.discountPrice = discountPrice
        this.perUnitPrice = perUnitPrice
        this.originalPerUnitPrice = originalPerUnitPrice

        this.originalPriceSpyder = originalPriceSpyder
        this.discountPriceSpyder = discountPriceSpyder
        this.discountMessageSpyder = discountMessageSpyder

        this.invalidPromoCode = false
        this.successPromoCode = true
        this.loadingPromoCode = false
      } catch (error) {
        console.log('error', error)
        this.$showAlert({
          message: error.response.data.error.message,
          type: 'error'
        })

        this.invalidPromoCode = true
        this.removePromoCode()
      }
    },
    removePromoCode (wipeTimer = false, clearPromoCode = true) {
      if (wipeTimer) {
        this.$emit('wipeCountdownTimer')
      }

      this.price = null
      this.discountMessage = null
      this.discountPrice = null
      this.perUnitPrice = null
      this.originalPerUnitPrice = null

      this.originalPriceSpyder = null
      this.discountPriceSpyder = null
      this.discountMessageSpyder = null

      this.loadingPromoCode = false
      this.successPromoCode = false

      if (clearPromoCode) {
        this.promoCode = null
      }
    },
    async pay () {
      this.loading = true
      console.log('paying...')

      try {
        let priceId
        // does not require error state because will always have a value
        if (this.paymentSchedule === 'Annual (Save 10%)') {
          console.log('annual')
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.plan.name
          ).annualPrice.id
        } else {
          console.log('monthly')
          priceId = this.subscriptions.find(
            (sub) => sub.name === this.plan.name
          ).price.id
        }
        // Check if we should upgrade
        if (
            this.getUserSubscription.product?.name === 'Inspiration' &&
            this.plan.name === 'Full Workflow'
        ) {
          console.log('upgrading...')
          await API.Stripe.upgradeSubscription(
            priceId,
            this.getStripeCustomer.stripeId
          )

          this.$showAlert({
            message: 'Your account has been upgraded. Thank you!',
            type: 'success'
          })

          if (this.paymentSchedule === 'Annual (Save 10%)') {
            console.log('annual upgrade')
            this.$router.push({ name: 'AnnualTeamThankYouRedirectView' })
          } else {
            console.log('monthly upgrade')
            this.$router.push({ name: 'MonthlyTeamThankYouRedirectView' })
          }

          return
        }

        const groupComponent = this.$refs.elms

        console.log('confirming card setup...')

        // Make an api call to create a payment intent using the current setup intent
        const result = await groupComponent.instance.confirmSetup({
          elements: groupComponent.elements,
          redirect: 'if_required',
          confirmParams: { return_url: 'https://app.foreplay.co/library' }
        })

        console.log('confirmed setup of card', result.setupIntent.status)

        // Send the Tax ID as well
        const taxId = this.$refs.taxId?.value

        const teamMembers = this.products.filter(
          (product) => product.name === 'Team Members'
        )[0]?.amount || null

        // Make an API endoint call to update the default payment method and create the payment intent
        console.log('creating subscription...')

        const spyderQuantity = this.products?.find(
          (product) => product.name === 'Spyder Brands'
        )?.amount || null

        const paymentSchedule = this.paymentSchedule === 'Annual (Save 10%)' ? 'yearly' : 'monthly'
        const { clientSecret } = await API.Stripe.createSubscription(
          priceId,
          this.getStripeCustomer.stripeId,
          this.promoCode,
          result.setupIntent.payment_method,
          taxId,
          teamMembers,
          spyderQuantity,
          paymentSchedule,
          !this.timerExpired && this.paymentSchedule === 'Monthly' && this.plan?.name === 'Full Workflow'
        )

        console.log('completed creating subscription!')

        let confirmPaymentResult = null
        if (clientSecret) {
          confirmPaymentResult = await this.$stripe.confirmCardPayment(
            clientSecret
          )
        }
        console.log('payment accepted? ', confirmPaymentResult)

        if (confirmPaymentResult?.error) {
          // Show Error
          this.$showAlert({
            message: confirmPaymentResult.error.message,
            type: 'error'
          })
        } else {
          this.$showAlert({
            message: 'Charge Succeeded. Thank you!',
            type: 'success'
          })

          const user = firebase.auth().currentUser
          await this.setUserSubscription(user)

          this.SET_TEAM_SUBSCRIPTION_STATE('active')

          this.SET_SHOW_UPSELL(true)

          if (paymentSchedule === 'monthly' && spyderQuantity <= 2) {
            this.SET_UPSELL_STEP(1)
          }

          this.loading = false
          this.success = true

          setTimeout(() => {
            this.$router.push({
              name: 'LibraryView'
            })
          }, 2000)
        }
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.response?.data?.error?.message || 'Something went wrong upgrading your acount.',
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.autofill:-webkit-autofill,
.autofill:-webkit-autofill:hover,
.autofill:-webkit-autofill:focus,
.autofill:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
